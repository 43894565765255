const loadToken = () => {
  try {
    const stor = localStorage.getItem('token');

    if (stor === null) return undefined;

    return stor;
  } catch (err) {
    return undefined;
  }
};

const saveToken = (token) => {
  try {
    const serializedToken = token;
    localStorage.setItem('token', serializedToken);
  } catch (err) {
    console.log(err);
  }
};

const removeToken = async () => {
  try {
    await localStorage.removeItem('token');
  } catch (err) {
    console.log(err);
  }
};

const loadSession = () => {
  let session = { token: null, user: null };
  try {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');

    session.token = token;
    session.user = JSON.parse(user);
  } catch (err) {}
  return session;
};

const saveSession = (token, user) => {
  try {
    const serializedToken = token;
    const serializedUser = JSON.stringify(user);

    if (serializedToken) localStorage.setItem('token', serializedToken);
    if (serializedUser) localStorage.setItem('user', serializedUser);
    return { serializedToken, serializedUser };
  } catch (err) {
    console.log(err);
  }
};

const removeSession = () => {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('tempInfo');
  } catch (err) {
    console.log(err);
  }
};

export { loadToken, saveToken, removeToken, loadSession, saveSession, removeSession };
