import React, { Component, Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./scss/style.scss";

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const Authentication = ({ children }) => {
	return loadToken() ? children : <Login />;
};

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

import { loadToken } from "./utils/localStorage";

class App extends Component {
	render() {
		return (
			<BrowserRouter>
				<Suspense fallback={loading}>
					<Routes>
						<Route exact path="/500" name="Page 500" element={<Page500 />} />
						<Route
							path="*"
							name="Home"
							element={
								<Authentication>
									<DefaultLayout />
								</Authentication>
							}
						/>
					</Routes>
				</Suspense>
			</BrowserRouter>
		);
	}
}

export default App;
